@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.curiosityScreen {
  flex: 1;
  display: flex;
  justify-content: space-around;
  margin-bottom: 8vh;
  z-index: 1000;

  .curiosityHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    .curiosityIcon {
      height: vh(15);
      width: vh(10);
    }

    .curiosityHeaderText {
      font-size: 2.8em;
      letter-spacing: 0.05em;
    }
  }

  .curiosityMultimedia {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: vh(4);

    .curiosityMultimediaVideo {
      width: 100%;
      height: vh(30);
      object-fit: cover;
    }

    .curiosityMultimediaIframe {
      width: 100%;
      height: vh(30);
    }

    .curiosityMultimediaImage {
      height: vh(30);
      border: vh(1) solid white;
      border-radius: vh(1);
    }
  }

  .curiosityText {
    flex: 2;
    margin-top: vh(4);
    overflow-y: scroll;

    .curiosityTextHeader {
      margin-bottom: vh(2);
    }
  }
}
