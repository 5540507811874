@mixin position-lt($horizontal, $vertical) {
  position: absolute;
  top: $vertical;
  left: $horizontal;
  -webkit-transform: translate(-$horizontal, -$vertical);
  transform: translate(-$horizontal, -$vertical);
}

@mixin small {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin wide {
  @media (min-width: 768px) {
    @content;
  }
}

@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

@keyframes swim-summary {
    0% {
        transform: translateY(-15%);
    }
  
    100% {
        transform: translateY(0%);
    }
  }

.pointsSummaryScreen {
  width: 100%;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;

  p {
    font-size: 1em;
    margin-bottom: vh(0.5);
    @include wide {
      font-size: 1.8em;
    }
  }

  .summaryHeader {
    position: relative;
    top: vh(-3);
  }

  .seahorseChestContainer {
    position: relative;
    width: 100%;
    top: vh(-2);

    .chestInfoText {
      @include position-lt(50%, 5%);
    }

    .pointsCount {
      @include position-lt(50%, 40%);
      font-size: 5em;
    }

    .pointsText {
      @include position-lt(50%, 85%);
      font-size: 1.5em;
    }

    .seahorseChestImageContainer {
        position: relative;
        height: vh(35);
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .seahorseImage {
            position: absolute;
            height: 80%;
            left: calc(50% - #{vh(26)});

            animation: swim-summary 2s infinite alternate ease-in-out;
        }
        .chestImage {
            height: 80%;
        }
    }
  }

  .buttonsSection {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pointsSummaryEnd {
      display: flex;
      flex-direction: column;
      align-items: center;

    }

    .pointsSummaryPlay {
      margin-top: vh(3);
      display: flex;
      flex-direction: column;
      align-items: center;

    }
  }

  .button {
    position: relative;
    height: vh(7);
    width: 45vw;
    margin: vh(2);
    padding: vh(2);
    font-size: 0.9em;
    text-transform: uppercase;
    border-radius: vh(6);
    box-shadow: 0 0 0 vh(1) rgba(255, 255, 255, 0.25);
    @include small {
      font-size: 0.8em;
    }

    h3 {
      @include position-lt(50%, 50%);
      white-space: nowrap;
    }
  }

  .checkAnimalButton {
    background-color: #009aef;
  }

  .continueButton {
    background-color: #fff;
  }
}
