@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.changeLanguage {
  &__overlay {
    position: absolute;
    width: 100vw;
    height: vh(100);
    background-color: rgba(0, 154, 239, 0.8);
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__prompt {
    width: 80vw;
    height: vh(80);

    background: white;
    border-radius: vh(4);

    &__header {
      height: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__list {
      height: 70%;
      overflow-y: scroll;

      &__element {
        position: relative;
        padding: vh(2);
        margin: vh(2);
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--selected {
          font-weight: bold;
          border-radius: vh(4);
          background-color: #e9f7d9;
        }

        &__text {
          &--selected {
            color: #90d63f;
          }
        }

        &__check {
          position: absolute;
          height: vh(4);
          right: vh(2);
          visibility: hidden;
          
          &--selected {
            fill: #90d63f;
            visibility: visible;
        }
        
        &--unselected {
            fill: #003a7f;
            visibility: visible;
          }
        }
      }
    }

    &__footer {
      height: 15%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      &__button {
        height: 50%;
        width: 40%;
        padding-top: 3px;
        border-radius: vh(5);
        font-size: 0.8em;
        display: flex;
        align-items: center;
        justify-content: center;

        &--cancel {
          background-color: #30b3ff;
        }

        &--confirm {
          background-color: #90d63f;
        }
      }
    }
  }
}
