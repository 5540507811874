@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.startGameContainer {
  justify-content: space-evenly;

  .stopwatch {
    width: vh(12);
  }

  .startGameInfo {
      font-size: 0.85em;
  }
}
