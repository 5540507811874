/**
 |--------------------------------------------------
 | GLOBAL FONTS
 |--------------------------------------------------
 */

@font-face {
  font-family: "metropolis";
  src: local("Metropolis-Medium"), url(../assets/fonts/metropolis/Metropolis-Medium.otf) format("truetype");
}

/**
 |--------------------------------------------------
 | GLOBAL VARIABLES
 |--------------------------------------------------
 */

$textColorLight: #fff;
$textColorDark: #003a7f;
$textColorSecondary: #009aef;
$textColorGold: #ffc000;

/**
   |--------------------------------------------------
   | MIXINS
   |--------------------------------------------------
*/

@mixin small {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin wide {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin long {
  @media (max-aspect-ratio: 9/18) {
    @content;
  }
}

/**
   |--------------------------------------------------
   | GLOBAL STYLES
   |--------------------------------------------------
*/

@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overscroll-behavior: none;
}

html,
body {
  position: fixed;
  overflow: hidden;
}

html {
  font-size: calc(0.6em + 1vw);
}

h1,
h2,
h3,
h4,
p {
  color: $textColorLight;
  text-align: center;
  font-weight: lighter;
  letter-spacing: 0.03em;
}

.colorLight {
  color: $textColorLight;
}

.colorDark {
  color: $textColorDark;
}

.colorSecondary {
  color: $textColorSecondary;
}

.colorGold {
  color: $textColorGold;
}

h1 {
  font-size: 3.3em;
  line-height: 1em;
  font-family: "Paytone One", sans-serif;
}

h2 {
  font-size: 2.2em;
  line-height: 1.1em;
  font-family: "Paytone One", sans-serif;
}

h3 {
  font-size: 1.7em;
  line-height: 1.4em;
  line-height: 1.2em;
  font-family: "Paytone One", sans-serif;
  letter-spacing: 0.02em;
  margin-bottom: 0.3em;
}

h4 {
  font-family: "Metropolis";
  font-size: 1.2em;
  line-height: 1.2em;
}

p {
  font-family: "Metropolis";
  font-size: 1.1em;
  line-height: 1.4em;
  letter-spacing: 0;
}

.label {
  font-size: 0.7em;
  line-height: 1.4em;
}
