@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.animalProfileScreen {
  display: grid;
  width: 100%;
  grid-auto-columns: 50%;
  grid-template-rows: vh(7) vh(8) vh(8) vh(7) vh(27) 1fr;
  grid-template-areas:
    "points-header points-header"
    "points-image points-amount"
    "points-image points-text"
    "profile-header profile-header"
    "profile-image profile-image"
    "profile-footer profile-footer";
  .pointsHeader {
    grid-area: points-header;
    font-size: 2em;
}
.pointsImage {
    grid-area: points-image;
    height: vh(14);
    display: flex;
    justify-content: center;
    img {
        height: vh(14);
    }
}
.pointsAmount {
    grid-area: points-amount;
}
.pointsText {
    grid-area: points-text;
}
.profileHeader {
    grid-area: profile-header;
    font-size: 2em;
  }
  .profileImage {
    grid-area: profile-image;
    position: relative;
    height: vh(23);
    display: flex;
    justify-content: center;
    img {
        height: vh(23);
        width: calc(#{vh(23)} * 4 / 3);
        border: vh(2) solid white;
        border-radius: vh(1);
    }
    .profileText {
        font-size: 1.7em;
        position: absolute;
        bottom: vh(4)
    }
  }
  .profileFooter {
    grid-area: profile-footer;
    font-size: 1em;
  }
}
