.screenText {
    font-family: inherit;
    color: inherit;
    line-height: inherit;
    display: flex;
    flex-direction: column;
    * {
        font-family: inherit;
        color: inherit;
        line-height: inherit;
        flex-grow: 1;
    }
}