@mixin wide {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin long {
  @media (max-aspect-ratio: 9/18) {
    @content;
  }
}

@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.sliderQuizScreen {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .sliderQuizHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 40%;

    h3 {
      margin-bottom: vh(3) ;
    }

    .sliderQuizImage {
      margin: vh(2) auto 0;
      max-height: vh(20);
      max-width: 100%;
      border: vh(1) solid;
      border-radius: vh(1);
      border-color: white;
      margin-bottom: vh(3);
    }
  }

  

  

  .sliderQuizContent {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .row {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 20%;
      color:white;
      .screenText * {
          font-family: "Metropolis" !important;
      }
    }
  
    .quizAnswers {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      width: 100%;
      margin: 0;
      max-height: vh(50);
      @include wide {
        width: 100%;
      }
    }

    
    .leftSide, .rightSide {
      height: 100%;
      width: 20%;
      display: flex;
      align-items: center;
    }

    .leftSide {
      justify-content: flex-end;
      text-align: right;
    }
    .rightSide {
      justify-content: flex-start;
      text-align: left;
    }
  
    .center {
      width: 50%;
      height: max-content;
      position: relative;
      display: flex;
      align-items: center;
  
      .placeholder {
        width:100%;
      }
  
      .answers {
        height: 100%;
        width: 100%;
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
  
        .answer {
          width: calc(79 * 100% / 587);
          height: 100%;
          background-size: cover !important;
          position: relative;

          .glow {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
            width: 200%;
          }
        }

        .hidden {
          visibility: hidden;
        }
      }
    }
  }
}
