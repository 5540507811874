@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.customCheckbox {
  width: vh(3);
  height: vh(3);
  background-color: transparent;
  border: vh(0.3) solid #003a7f;
  border-radius: 5;
  margin-right: vh(1.5);

  &--checked {
    background-color: #003a7f;
  }
}
