@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.scrollGameScreen {
  overflow: hidden;
  touch-action: none;
  display: flex;
  justify-content: center;


  .scrollGameText {
    text-align: center;
    position: absolute;
    color: white;
    width: 75vw;
    height: vh(20);
    top: vh(12.5);
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .scrollGameBalloon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 25vw;
    z-index: 1;
  }

  .scrollGameCave {
    width: 100vw;
    position: absolute;
    top: 0;
    overflow: hidden;
  }
}
