@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.checkboxScreen {
  .logotypesContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-height: vh(27);
    position: absolute;
    bottom: vh(15);
    background-color: #fff;
  }

  .logotype {
    height: vh(10);
    object-fit: contain;
  }

  .headerContainer {
    margin-top: vh(7);
  }

  .checkboxContainer {
    margin-top: vh(7);
    display: flex;
    align-items: center;
  }

  .regulationContainer {
    margin-top: vh(3.5);
    display: flex;
    flex-flow: column;
    justify-content: center;

    .regulationLink {
      text-decoration: none;
      font-family: "Metropolis";
    }
  }

  .footerContainer {
    position: absolute;
    bottom: vh(4);
    width: 60%;
  }
}
