@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

$card-height: vh(8);
$header-height: 35%;

.crocodileQuizScreen {
  justify-content: space-evenly;

  .plankImage {
    position: absolute;
    top: 0;
    height: vh(35);
    width: 100vw;
}

  .trashImage {
    position: absolute;
    top: 0;
    left: 50%;
    height: vh(30);
    transform: translate(-50%, 0);
  }

  .crocodileImage {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: vh(30);
    transform: translate(-50%, 0);
  }

  .crocodileQuizGame {
    width: 100%;
    height: calc(100% - #{$header-height});
    position: relative;
    .crocodileQuizAnimatable {
      position: absolute;
      width: 100%;
      height: 100%;
      will-change: transform;
      display: flex;
      align-items: center;
      justify-content: center;

      .crocodileQuizCard {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: $card-height;
        height: $card-height;
        will-change: transform;
        border-radius: 10vw;
      }
    }
    .crocodileQuizCircle {
      position: absolute;
      width: $card-height;
      height: $card-height;
      pointer-events: none;
      background-color: white;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      .crocodileQuizPoints {
        position: absolute;
        font-size: vh(6);
        font-family: "Paytone one";
        color: white;
        padding-bottom: 5px;
      }
    }

    .crocodileQuizControls {
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex;

      justify-content: center;
      align-items: center;

      flex-direction: column;

      .crocodileQuizArrows {
        height: vh(40);
      }
    }
  }
}
