@mixin vertical {
  @media (max-aspect-ratio: 1/1) {
    @content;
  }
}

@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.container {
  height: vh(100);
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-image: url("/images/desktop/webbg.png");

  .contentContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    @include vertical {
      flex-direction: column;
      justify-content: space-between;
    }
    .img_phone {
      height: 60%;

      @include vertical {
        height: 40%;
      }
    }

    .img_qr_code {
      height: 50%;
      @include vertical {
        height: 30%;
        margin-bottom: vh(20);
      }
    }
  }
}

.desktopLogotypesContainer {
  position: absolute;
  width: 100%;
  background-color: white;
  height: max-content;
  bottom: 0;
  display: flex;
  justify-content: center;
  .img_logotypes {
    width: 50%;
    @include vertical {
      width: 100%;
    }
  }
}
