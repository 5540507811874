
@function vh($height) {
    @return calc(var(--vh, 1vh) * #{$height});
  }  

.puzzleScreen{
    justify-content: center;
    align-items: center;

    .puzzleTextContainer {
        position: absolute;
        top: vh(6);
        height:  calc(#{vh(50)} - 25vw - #{vh(6)});
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        
    }
}