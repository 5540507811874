@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

$card-height: vh(30);
$header-height: 35%;

.swipeQuizScreen {
  justify-content: space-evenly;

  .swipeQuizGame {
    width: 100%;
    height: calc(100% - #{$header-height});
    position: relative;
    .animatable {
      position: absolute;
      width: 100%;
      height: 100%;
      will-change: transform;
      display: flex;
      align-items: center;
      justify-content: center;

      .card {
        background-color: white;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: calc(#{$card-height} * 4 / 3);
        height: $card-height;
        will-change: transform;
        border-radius: 10vw;
        border: vh(2) solid white;
        transition: border 0.1s;
      }
    }

    .swipeQuizControls {
      width: 100%;
      height: 100%;

      display: flex;

      justify-content: space-between;
      align-items: center;

      flex-direction: column;

      .arrowContainer {
        position: relative;
        height: vh(18);

        .arrow {
          height: 100%;
        }

        .arrowGlow {
          height: 113%;
          width: 126%;
          position: absolute;
          left: -13%;

          &--up {
            bottom: 0;
          }

          &--down {
            top: 0;
          }
        }

        .topContent,
        .bottomContent {
          position: absolute;
          height: 55%;
          width: 100%;

          display: flex;
          text-align: center;
          justify-content: center;

          .text {
            width: min-content;
            font-size: vh(2.2);
            font-family: 'Paytone One', sans-serif;
            color: white;
          }
        }

        .topContent {
          top: 0;
          align-items: flex-end;
        }

        .bottomContent {
          bottom: 0;
          align-items: flex-start;
        }
      }
    }
  }
}
