@mixin position-lt($horizontal, $vertical) {
  position: absolute;
  top: $vertical;
  left: $horizontal;
  transform: translate(-$horizontal, -$vertical);
}

@mixin small {
  @media (max-height: 400px) {
    @content;
  }
}

@mixin wide {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin long {
  @media (max-aspect-ratio: 9/18) {
    @content;
  }
}
@mixin short {
  @media (max-height: 768px) {
    @content;
  }
}

@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

#root {
  height: vh(100);
  width: 100vw;
  margin: 0 auto !important;
  float: none !important;
}

$header-height: vh(6);
$footer-height: vh(9);

.screenContainer {
  position: absolute;
  height: vh(100);
  width: 100vw;
  top: 0;
  left: 0;
  background-size: cover;

  display: grid;
  grid-template-areas:
    "header"
    "content"
    "footer";
  grid-template-rows: vh(6) 1fr vh(9);

  .screenContent {
    grid-area: content;
    width: 100vw;
  }

  .screenSection {
    width: 100vw;
    display: flex;
    justify-content: space-between;

    @include small {
      display: none;
    }

    &--header {
      grid-area: header;
    }


    &--footer {
      grid-area: footer;
    }

    .sectionElement {
      position: relative;
      display: flex;
      height: 100%;
      z-index: 10;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      $multiplier: 8;

      .right-hand {
        justify-self: flex-end;
        align-self: flex-end;
      }

      &--left-top {
        background-image: url("../../assets/images/bg_white_left_top_corner_wide.png");
        background-position: calc(#{$header-height} * (-#{$multiplier} + 1) + 4em) 0%;
        background-size: calc(#{$header-height} * #{$multiplier}) $header-height;
        width: 50%;
        justify-self: flex-start;
        display: flex;
        flex-direction: row;
      }

      &--right-top {
        background-image: url("../../assets/images/bg_white_right_top_corner_wide.png");
        width: 8em;
        height: $header-height;
        background-position: 0;
        background-size: calc(#{$header-height} * #{$multiplier}) $header-height;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--left-bottom {
        justify-self: flex-start;
        background-position: left bottom;
        width: 25%;
      }

      &--right-bottom {
        justify-self: flex-end;
        background-position: right bottom;
        width: 25%;
        display: flex;
        justify-content: flex-end;
      }

      &--center {
        justify-self: center;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      &__image {
        width: $header-height;

        &--right-bottom {
          align-self: flex-end;
          justify-self: flex-end;
          width: $header-height;
          padding: 0 vh(1) vh(1) 0;
        }

        &--left-bottom {
          align-self: flex-start;
          justify-self: flex-end;
          height: $footer-height;
          width: $footer-height;
          padding: vh(1);
        }

        &--left-top {
          align-self: flex-start;
          justify-self: flex-start;
          height: $header-height;
          width: $header-height;
          padding: vh(0.8);
        }

        &--language {
          height: $footer-height;
          width: $footer-height;
          padding: vh(2) vh(2) vh(1) vh(1);
        }

        &--curiosity {
          height: $footer-height;
          width: $footer-height;
          padding: vh(1) vh(3) vh(1) vh(1.3);
        }

        &--close {
          padding: vh(1) vh(1) vh(2) vh(2);
        }
      }

      &__text {
        text-transform: uppercase;
      }

      .pointsCounter {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;

        .pointsMultiplier {
          height: 100%;
          font-size: 1.4em;
          font-family: "Paytone One", sans-serif;
          display: flex;
          align-items: center;
          padding-bottom: 5px;
          margin-right: 2px;
        }

        .pointsCount {
          height: 100%;
          font-size: 2.1em;
          display: flex;
          align-items: center;
          margin: 0;
          padding-bottom: 5px;
        }
      }
      .timer {
        .timerText {
          font-size: 1.9em;
        }

        .timerRed {
          color: red;
          animation: pulse 1s infinite;
        }
      }

      .closeButton {
        justify-self: flex-end;
        background-position: right top;
        width: 25%;
      }

      .scoreText {
        @include position-lt(75%, 50%);
        display: block;
        line-height: 1em;
        font-size: 4em;
        font-family: "Paytone One", sans-serif;
        margin-bottom: 0;
      }
    }
  }
  .progressBar {
    width: 100%;
    background-color: #fff;
    border-color: transparent;
    border-radius: 10px;

    &__bar {
      height: vh(1.5);
      background-color: #ffc000;
      border-radius: 10px;
    }
  }
}
.screenContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5vw;
  padding-right: 5vw;
  height: 100%;
}

.rotationOverlay {
  height: vh(100);
  width: 100vw;

  position: absolute;
  background: #009aef;

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

  z-index: 1000;

  * {
    width: 30%;
  }
}

.offlineOverlay {
    height: vh(100);
    width: 100vw;

    z-index: 950 !important;

  position: absolute;
  background: url("../../assets/images/bg_light_example.png");
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

    .offlineImage {
        width: 50vw;
    }
    .offlineText {
        margin: vh(10) 0;
        padding: 0 2.5vw;
        font-size: 2em;
    }

    * {
        height: vh(20);
        width: 100vw;
    }
}

.idleOverlay {
  height: vh(100);
  width: 100vw;

  position: absolute;
  background: rgba(0, 154, 239, 0.8);

  z-index: 900;

  .idleOverlayHand {
    position: absolute;
    height: 50vw;
    width: 50vw;
    top: vh(50);
    transform: translate(-50%, -50%);

    animation-name: swipe;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    .idleOverlayText {
      font-size: 3em;
      font-family: "Metropolis";
      font-weight: bold;
      color: white;
      text-align: right;
    }

    .idleOverlayImage {
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes swipe {
  from {
    left: 70vw;
  }
  to {
    left: 30vw;
  }
}

.swipeContainer {
  z-index: 10;
  top: 0;
  bottom: 0;
  overflow: hidden;
  height: vh(91);
  width: 100vw;
  position: absolute;
  left: 50%;
  background-color: transparent;
}

.appContainer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: vh(100);
  width: 100vw;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .sectionContainer {
    display: flex;
    height: $footer-height;
    justify-content: space-between;
    width: 100%;

    &--header {
      justify-self: flex-start;
      align-items: flex-start;
      height: $header-height;
    }

    &--footer {
      justify-self: flex-end;
      align-items: flex-end;
    }
  }
}

.current-screen-enter {
  opacity: 0;
  z-index: 0;
  pointer-events: none;
}
.current-screen-enter-active {
  opacity: 1;
  z-index: 1;
  transition: 200ms;
  pointer-events: none;
}
.current-screen-enter-done {
  opacity: 1 ;
  z-index: 1 ;
  pointer-events: all;
}

.current-screen-exit {
  opacity: 1;
  z-index: 1;
  pointer-events: none;
}
.current-screen-exit-active {
  opacity: 0;
  z-index: 0;
  transition: 200ms;
  pointer-events: none;
}
.current-screen-exit-done {
  pointer-events: none;
  opacity: 0;
  z-index: 0;
}
