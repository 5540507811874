@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

@keyframes swim-navigation {
  0% {
    transform: translate(0, -5%);
  }

  100% {
    transform: translate(0, 5%);
  }
}

.tutorialScreen {
  align-items: center;

  .tutorialMovieContainer {
    display: flex;
    align-items: center;
  }
  .tutorialMovie {
    max-height: vh(85);
    -webkit-box-shadow: 2px 9px 26px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 9px 26px -5px rgba(0,0,0,0.75);
    box-shadow: 2px 9px 26px -5px rgba(0,0,0,0.75);
  }
}
