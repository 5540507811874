@mixin position-lt($horizontal, $vertical) {
  position: absolute;
  top: $vertical;
  left: $horizontal;
  transform: translate(-$horizontal, -$vertical);
}

@mixin wide {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin long {
  @media (max-aspect-ratio: 9/18) {
    @content;
  }
}

@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

@keyframes swim {
  0% {
    transform: translate(0, 5%);
  }

  100% {
    transform: translate(0%, -5%);
  }
}

.startScreen {
  .logotypesContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: vh(10);
    position: absolute;
    top: vh(1.5);

    .logotype {
      height: 100%;
      max-width: 40vw;
      padding: vh(1);
      object-fit: contain;
    }
  }

  .startScreenTexts {
    margin-top: vh(13);

    .startScreenHeader {
      font-size: 1.8em;
      margin-top: vh(2);
    }
  }

  .startButton {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .seahorse {
    position: absolute;
    left: calc(20% - #{vh(9)});
    top: calc(60% - #{vh(18)});
    animation: swim 2s infinite alternate ease-in-out;
    height: vh(36);
    @include long {
      height: vh(33);
    }
  }
}
