@mixin wide {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin long {
  @media (max-aspect-ratio: 9/18) {
    @content;
  }
}

@mixin position-absolute($left, $top, $rotation: 0deg) {
  position: absolute;
  top: $top;
  left: $left;
  transform: translate(-50%, -50%) rotate($rotation);
}

@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

@keyframes swim-popup {
  0% {
    transform: translate(0%, 25%);
  }

  100% {
    transform: translate(0%, 20%);
  }
}

@keyframes drop {
  0% {
    top: 40%;
    opacity: 1;
  }

  75% {
    top: 61%;
    opacity: 1;
  }

  100% {
    top: 61%;
    opacity: 0;
  }
}

.winLoseScreen {
  .seahorseContainer {
    position: relative;
    top: 50%;
    height: vh(60);
    animation: swim-popup 2s infinite alternate ease-in-out;

    $gap: vh(8);

    .seahorseImage {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(calc(-50% - #{$gap}), calc(-50% + 0vh));
      height: vh(60);
    }
    .chatImage {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(calc(-50% + #{$gap}), calc(-50% - 22vh));
      height: vh(18);
    }

    .chatText {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(calc(-50% + #{$gap}), calc(-50% - 23.5vh)) rotate(10deg);
      font-size: vh(5);
      width: min-content;
      text-transform: uppercase;

      &--lose {
        font-size: vh(3);
      }
    }
  }

  .chestImage {
    @include position-absolute(65%, 65%);
    height: vh(35);
  }

  .coinImage {
    @include position-absolute(62%, 40%);
    height: vh(6);
    animation: drop 1s ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .infoTextContainer {
    @include position-absolute(50%, 87%);
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 2em;

    .points {
      font-size: 5em;
      font-family: "Paytone One", sans-serif;
      transform: rotate(-5deg);
    }
    .infoText {
      font-size: 1.6em;
      white-space: nowrap;
      margin-top: 0.5em;
      margin-left: 2vw;

      &--lose {
        font-size: 2em;
      }
    }
  }
}
