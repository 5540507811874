@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.facefilterScreen {
  .facefilterHeader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: vh(6);
    z-index: 20;
    display: flex;
    justify-content: flex-end;

    .faceFilterCloseButton {
      height: vh(6);
      width: vh(6);
      background-size: vh(6) vh(6);

      &__image {
        height: vh(6);
        width: vh(6);
        padding: vh(1) vh(1) vh(2) vh(2);
      }
    }
  }

  .facefilterChoiceBar {
    z-index: 20;
    height: 10vh;
    width: 100vw;
    position: absolute;
    left: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.5);

    .choice {
      height: 6vh;
      width: 6vh;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      background-color: white;
      border-radius: 50%;
    }

    .choice {
      height: 6vh;
      width: 6vh;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 50%;

      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ring {
      position: absolute;
      height: 7.5vh;
      width: 7.5vh;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 0.4vh solid white;
      background-color: transparent;
      border-radius: 50%;
    }
  }

  .facefilterCanvas {
    z-index: 10;
    position: absolute;
    max-height: 100vh;
    max-width: calc(100vh * 2 / 3);
    left: 50%;
    top: 50%;
    width: 100vmin;
    transform: translate(-50%, -50%) rotateY(180deg);
  }

  .facefilterOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #ffc831;
  }
}
