@mixin small {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin wide {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin long {
  @media (max-aspect-ratio: 9/18) {
    @content;
  }
}

@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.playButtonContainer {
  position: relative;
  height: vh(28);
  width: vh(28);
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include long {
    height: vh(24);
    width: vh(24);
  }

  .buttonText {
    position: absolute;
    transform: rotate(-3deg);
    font-size: vh(7);
    text-transform: uppercase;
    color: #ffc000;
    letter-spacing: 0;
    @include long {
        font-size: vh(6);
      }
  }
}
