@mixin position($horizontal, $vertical) {
  top: $vertical;
  left: $horizontal;
  transform: translate(-$horizontal, -$vertical);
}

@mixin small {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin wide {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin long {
  @media (max-aspect-ratio: 9/18) {
    @content;
  }
}

@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

@keyframes swim {
  0% {
    transform: translate(0, 2%);
  }

  100% {
    transform: translate(0%, -6%);
  }
}

.withNewsletterEndScreen {
  .logotypesContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    height: vh(10);
    top: 0;

    .logotype {
      height: 100%;
      max-width: 40vw;
      padding: vh(1);
      object-fit: contain;
    }
  }

  .textContainer {
    z-index: 2;
    height: vh(30);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    p {
      font-size: 1.3em;
      @include wide {
        font-size: 1.8em;
      }
      @include small {
        font-size: 1em;
      }
    }
  }

  .imageContainer {
    position: relative;
    height: vh(45);
    width: 100%;

    animation: swim 2s infinite alternate ease-in-out;

    .seahorseImage {
      height: vh(45);
      position: absolute;
      left: calc(50% - #{vh(11.25)} - #{vh(5)});
      top: 0;
    }

    .textBackground {
      position: absolute;
      height: vh(15);

      left: calc(50% - #{vh(15) / 4} + #{vh(5)});
      top: 37%;
    }
    .imageText {
      position: absolute;
      left: calc(50% - #{vh(5) / 4} + #{vh(5)});
      top: 50%;
      transform: rotate(15deg);
      width: min-content;
      font-size: vh(2.4);
    }
  }
  a {
    text-decoration: none;

    .newsletterButton {
      height: vh(7);
      width: max-content;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: vh(2);
      padding: vh(2);
      font-size: 0.85em;
      text-transform: uppercase;
      border-radius: vh(6);
      box-shadow: 0 0 0 vh(1) rgba(255, 255, 255, 0.25);
      background-color: #fff;
      @include long {
        width: 90vw;
      }
      @include small {
        bottom: vh(-6);
        font-size: 0.7em;
      }

      h3 {
        white-space: nowrap;
        margin: 0;
      }
    }
  }
}
