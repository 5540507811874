
@function vh($height) {
    @return calc(var(--vh, 1vh) * #{$height});
}

.statisticsOverlay {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    height: vh(100);
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    transition: 1s;

    .statisticsPopup {
        display: flex;
        align-items: center;
        justify-content: center;
        height: vh(20);
        width: 80vw;
        background-color: white;
        border-radius: vh(2);

        .statisticsIcon {
            height: 50%;
        }
        
        .statisticsDivider {
            font-family: 'Paytone One', sans-serif;
            font-size: 2em;
            margin: 0 2vw;
        }

        .statisticsPoints {
            font-size: 3.5em;
        }
    }
}