@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.quiz {
  display: grid;
  &--column {
    //nad
    &--text-answers {
      &--image {
        grid-template-areas:
          "header"
          "question"
          "answers";
        grid-auto-rows: max-content;
        place-content: space-evenly;
      }
      &--no-image {
        grid-template-areas:
          "header"
          "answers";
        grid-auto-rows: max-content;
        place-content: space-evenly;
      }
    }

    &--image-answers {
      &--image {
        grid-template-areas:
          "header"
          "question"
          "answers";
        grid-auto-rows: max-content;
        place-content: space-evenly;
      }
      &--no-image {
        grid-template-areas:
          "header"
          "answers";
        grid-auto-rows: max-content;
        place-content: space-evenly;
      }
    }

    .quiz__answers {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      grid-area: answers;

      $side: 42.5vw;

      &--image-answers {
        .quiz__answers__answer {
          width: $side;
          height: $side;

          display: flex;
          align-items: center;
          border: vh(1) solid;
          border-radius: vh(1);
          border-color: white;

          margin: 2.5vw 0;

          &--image {
            display: block;
            margin: 0 auto;
            width: 100%;
            height: 100%;
          }

          &--correct {
            border-color: #90d63f;
          }

          &--wrong {
            border-color: #ff3e45;
          }

          &--selected {
            border-color: #30b3ff;
          }
        }
      }

      &--text-answers {
        .quiz__answers__answer {
          display: flex;
          justify-content: center;
          align-items: center;

          width: $side;
          height: $side / 2;
          margin: 2.5vw 0;

          background-color: white;
          border-radius: vh(2);

          &--correct {
            background-color: #90d63f;
          }

          &--wrong {
            background-color: #ff3e45;
          }

          &--selected {
            background-color: #30b3ff;
          }

          &--text {
            display: block;
            margin: 0 auto;
            text-align: center;
            font-size: 1em;
            max-width: 100%;
          }
        }
      }
      &--1 {
        justify-content: center;
      }
      &--3 {
        .quiz__answers__answer {
          flex-flow: column nowrap;
          width: 100%;
          padding: 5vw 0;
        }
      }
    }
  }

  &--row {
    //obok
    grid-template-areas:
      "header header"
      "question answers";

    grid-template-columns: 45vw 45vw;
    grid-template-rows: max-content max-content;
    place-content: space-evenly;

    .quiz__answers {
      flex-direction: column;
      width: 100%;
      .quiz__answers__answer {
        display: flex;
        justify-content: center;
        align-items: center;

        $side: 42.5vw;

        width: $side;
        height: $side / 3;
        margin: 2.5vw 0;

        background-color: white;
        border-radius: vh(2);

        &--correct {
          background-color: #90d63f;
        }

        &--wrong {
          background-color: #ff3e45;
        }

        &--selected {
          background-color: #30b3ff;
        }

        &--text {
          display: block;
          margin: 0 auto;
          text-align: center;
          font-size: 1em;
          max-width: 100%;
        }
      }
    }
  }

  &--select-area {
    grid-template-areas:
      "header"
      "answers";
    .quiz__answers {
      box-sizing: content-box;
      width: 81vw;
      justify-self: center;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      border: vh(1) solid white;
      border-radius: vh(1);

      .quiz__answers__answer {
        box-sizing: border-box;
        width: 27vw;
        height: 27vw;
        border: vh(0.3) solid white;
        border-radius: 0;
        &--image {
          width: 100%;
          height: 100%;
        }

        &--selected {
          background-color: #30b3ff;
          .quiz__answers__answer--image {
            opacity: 0.5;
          }
        }
        &--correct {
          background-color: #90d63f;
          .quiz__answers__answer--image {
            opacity: 0.5;
          }
        }
        &--wrong {
          background-color: #ff3e45;
          .quiz__answers__answer--image {
            opacity: 0.5;
          }
        }
      }
    }
  }

  &__header {
    grid-area: header;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__question {
    grid-area: question;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--row {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;

      .quiz__content__question {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }

      .quiz__content__answers {
        width: 40vw;
        height: min-content;

        &--text {
          .quiz__content__answers__answer {
            width: 100%;
            height: vh(7);
            padding: vh(1);
            margin: vh(1) 0;
            display: flex;
            align-items: center;
            background-color: white;
            border-radius: vh(2);

            &--correct {
              background-color: #90d63f;
            }

            &--wrong {
              background-color: #ff3e45;
            }

            &--selected {
              background-color: #30b3ff;
            }
          }
        }
      }
    }

    &--column {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .quiz__question {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin: vh(3) 0;
      }
    }
    &__image {
      border: vh(1) solid white;
      border-radius: vh(1);

      &--above {
        max-height: vh(30);
        margin: 0 auto;
      }

      &--next {
        width: calc(100% - 2.5vw);
      }
    }

    &__count {
      font-size: 0.85em;
    }
  }

  &__answers {
    width: 90vw;
    display: flex;
    justify-content: space-between;
    align-content: center;

    &--next {
      margin-left: 2.5vw;
    }

    &--image {
      &--1 {
        justify-content: center;
      }

      &--3 {
        height: 90vw;
      }

      &--4 {
        height: 90vw;
      }

      &--5-9 {
        height: 90vw;

        .quiz__content__answers__answer {
          height: 25vw;
          width: 25vw;
        }
      }
    }

    &--text-answers {
      .quiz__answers__answer {
        border-radius: vh(2);

        .text {
          display: block;
          margin: 0 auto;
          text-align: center;
          font-size: 1em;
          max-width: 100%;
        }

        &--correct {
          background-color: #90d63f;
        }

        &--wrong {
          background-color: #ff3e45;
        }

        &--selected {
          background-color: #30b3ff;
        }
      }
    }
  }
}
