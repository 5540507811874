@mixin wide {
  @media (min-width: 768px) {
    @content;
  }
}

@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.withInstitutionsListScreen {
  padding-left: 10vw;
  padding-right: 10vw;

  flex-flow: column;
  justify-content: space-between;

  .withInstitutionsTop {
    flex: 1;
  }

  .withInstitutionsContent {
    white-space: pre-wrap;
    flex: 5;
    width: 100%;
    .withInstitutionsText {
      height: vh(60);
      width: 100%;
      overflow-y: scroll;

      a {
        text-decoration: none;
        font-weight: 1000;
        color: #009AEF;
      }
    }
  }

  .withInstitutionsBottom {
    flex: 1;
    .withInstitutionsLink {
      text-decoration: none;
      font-weight: bold;
    }
  }

  & > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
