@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}
//1480 2320
@mixin longDevice {
  @media (min-aspect-ratio: 1480/2320) {
    @content;
  }
}
$header-height: vh(6);

.sectionElement {
  position: relative;
  display: flex;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  &__image {
    width: $header-height;
    &--close {
      padding: vh(1) vh(1) vh(2) vh(2);
    }
    &--right-bottom {

      align-self: flex-end;
      justify-self: flex-end;
      width: $header-height;
      padding: 0 vh(1) vh(1) 0;
    }


  }
  &--right-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 120;
    justify-self: flex-end;
    background-position: right bottom;
    width: 25%;
    height: vh(9);
    display: flex;
    justify-content: flex-end;

  }

}

.map {
  &__overlay {
    position: absolute;
    width: 100vw;
    height: vh(100);
    background-color: rgba(0, 154, 239, 0.8);
    z-index: 120;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__closeButton {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 120;
    height: vh(6);
  }

  &__perspectiveSwitchButton {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 120;
    //height: vh(6);
    justify-self: flex-end;
    width: 25%;
    display: flex;
    justify-content: flex-end;
  }

  &__transformable {
    height: vh(100);
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__image {
      position: absolute;
      height: vh(100);
      width: auto;
      @include longDevice {
        height: auto;
        width: 100vw;
      };
    }
  }
}