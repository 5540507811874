@mixin wide {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin long {
  @media (max-aspect-ratio: 9/18) {
    @content;
  }
}

@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.pairQuizScreen {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  .pairQuizHeader {
      height: max-content;
  }

  .pairQuizAnswers {
    display: flex;
    flex-direction: row;
    height: vh(60);
  }

  #canvas {
    width: vh(10);
  }

  #leftSide,
  #rightSide {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .answer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: white;
    text-align: center;
    padding: vh(1);
    width: vh(15);
    height: vh(15);
    border-radius: vh(2);
    
    &--large {
        width: vh(17);
        height: vh(17);
    }

    .pairQuizOverlay {
      width: vh(13);
      height: vh(13);
      background-color: #003a7f;
      border-radius: vh(1);
      position: absolute;

      .image {
        opacity: 0.4;
      }
    }

    h3 {
      font-size: 1em;
      z-index: 2;
    }

    .image {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      border-radius: vh(1);
      opacity: 1;
      position: relative;
    }

    &--selected {
      background-color: #30b3ff;
    }

    &--correct {
      background-color: #90d63f;
    }

    &--wrong {
      background-color: #ff3e45;
    }
  }
}
