@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

@keyframes swim-navigation {
  0% {
    transform: translate(0, -5%);
  }

  100% {
    transform: translate(0, 5%);
  }
}

.navigationScreen {
  align-items: center;
  justify-content: space-evenly;

  .textContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: vh(15);
  }

  .graphicContainer {
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    width: 100%;
    max-width: vh(50);

    &__image {
      width: 100%;
      object-fit: cover;
      border: vh(2) solid #fff;
      border-radius: vh(4);
    }

    .animationsContainer {
      position: absolute;
      z-index: 5;
      width: 50%;
      height: 33%;
      display: flex;
      justify-content: center;
      align-items: center;

      &__image {
        height: 80%;
        animation: swim-navigation 1s infinite alternate ease-in-out;
      }
    }
  }

  .roundGraphicContainer {
    position: relative;
    height: 45%;

    .roundGraphicBorder {
      height: 100%;
    }

    .roundGraphicImage {
      position: absolute;
      top: 15.2%;
      left: 20.2%;
      width: 59.4%;
      height: 69.6%;
      border-radius: 50%;
    }
  }
}
