@function vh($height) {
  @return calc(var(--vh, 1vh) * #{$height});
}

.completeWordScreen {
  width: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;

  .completeWordImage {
    max-height: vh(30);
    max-width: 100%;
    border: vh(1) solid white;
    border-radius: vh(1);
  }

  .completeWordContent {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    .completeWordBreak {
      flex-basis: 100%;
    }
    .completeWordGap {
      flex: 1;
      height: 50px;
      max-width: 40px;
      background-color: white;
      border-radius: 10px;
      font-family: "Paytone one";
      justify-content: center;
      align-items: center;
      display: flex;
      margin: vh(1);
    }
  }
}
